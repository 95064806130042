.PageModuleSixColumns .ColumnItem .icon {
    height: 80px;
    width: 80px;
    position: relative;
    margin: 0 auto;
}

.PageModuleSixColumns .ColumnItem p {
    margin: 0;
    padding: 0;
    line-height: 28px;
    font-size: 15px;
}

.PageModuleSixColumns .ColumnItem p:first-of-type {
    margin-top: 10px;
}