.CheckoutFormContent .consent {
  margin: 24px 0 8px;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  line-height: 20px;
}

.CheckoutFormContent .CustomButton {
  width: 100%;
  margin: 0 0 8px;
}