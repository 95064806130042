.DraggableNotice {
    padding: 15px;
    overflow: hidden;
    position: fixed;
    bottom: 30%;
    right: 0;
    z-index: 12;
    text-align: center;
    width: 330px;
    cursor: move;
    display: table;
    border: none;
    box-shadow: 0 5px 10px grey;
    background-color: rgb(var(--color-yellow), 0.8);
}

.DraggableNotice .closeBtn {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.DraggableNotice h3 {
    font-size: 21px;
    font-family: 'Libre Baskerville', serif;
    color: black;
    margin: 0 32px 24px 0;
}

.DraggableNotice .image img {
    height: 120px;
    margin-bottom: 16px;
}

.DraggableNotice .findOut {
    float: right;
    font-size: 22px;
    color: black;
}