.WelcomeKitQuizNotAnswered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0;
}

.WelcomeKitQuizNotAnswered h1 {
  font-size: 28px;
  text-align: center;
}

.WelcomeKitQuizNotAnswered .RichText p {
  font-size: 20px;
  text-align: center;
  font-family: "Libre Baskerville", serif;
}

.WelcomeKitQuizNotAnswered .RichText p:first-child {
  margin-top: 32px;
}

.WelcomeKitQuizNotAnswered a {
  font-size: 18px;
  margin-top: 48px;
}