.LoginModal {
    background-color: rgb(var(--color-green));
    padding: 32px;
}

.LoginModal .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.LoginModal .closeLoginModal {
    background: none;
    color: white;
}

.LoginModal h3 {
    color: white;
    font-size: 30px;
}

.LoginModal .loginForm {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.LoginModal .loginForm input {
    background: none;
    padding: 10px;
    color: white;
    border-bottom: 2px solid white;
    width: 100%;
    font-size: 20px;
    font-family: 'Roboto Mono', monospace;
    margin-bottom: 10px;
}

.LoginModal ::placeholder {
    color: white;
    opacity: 0.8;
}

.LoginModal .forgotPassword {
    color: #fff;
    font-family: 'Roboto Mono', monospace;
    font-size: 13px;
    background: none;
    padding: 4px 0px;
    width: fit-content;
}

.LoginModal .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.LoginModal .actions li .social-sign-in {
    cursor: pointer;
}

.LoginModal .actions li a {
    color: white;
}

.LoginModal .actions .separator {
    padding: 0 12px;
    vertical-align: middle;
    color: white;
}

.LoginModal .submitBtn {
    width: fit-content !important;
    border-bottom: none !important;
    font-size: 24px !important;
    padding: 0px !important;
    background: var(--img-right-arrow-white) no-repeat right center !important;
    background-position: 85% center;
    padding-right: 32px !important;
    margin-top: 32px;
}

.LoginModal .errorMessage {
    align-self: center;
    color: white;
    font-weight: bold;
}