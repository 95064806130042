.MenuSideBar {
    margin-top: 40px;
}

.MenuSideBar li a {
    display: table;
    width: 100%;
    box-shadow: none;
    background: none;
    padding: 25px 5px;
    margin: 0px;
    border-bottom: 1px solid #e1e1e1;
    color: #333;
}

.MenuSideBar li.active a {
    color: rgb(var(--color-blue-4));
}