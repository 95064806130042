.EditAccountInfoModal {
    padding: 32px;
    background-color: rgb(var(--color-cream));
}

.EditAccountInfoModal h3 {
    color: rgb(var(--color-green));
    font-family: "DM Sans", sans-serif;
    margin-bottom: 24px;
}

.EditAccountInfoModal .userDataContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.EditAccountInfoModal input {
    background-color: transparent;
    border: 2px solid rgb(var(--color-green));
    padding: 8px 12px;
}

.EditAccountInfoModal .gradeListHeader {
    color: rgb(var(--color-green));
    font-family: "DM Sans", sans-serif;
    margin-top: 24px;
    font-weight: 600;
}

.EditAccountInfoModal .gradeListContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.EditAccountInfoModal .gradeListContainer h4 {
    width: 72px;
    color: rgb(var(--color-green));
    margin-right: 16px;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
}

.EditAccountInfoModal .gradeListContainer div {
    flex: 1;
    margin: 0;
}

.EditAccountInfoModal .gradeList {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
}

.EditAccountInfoModal .gradeList .grade {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.EditAccountInfoModal .gradeList label {
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 4px;
    text-align: center;
    color: rgb(var(--color-green));
}

.EditAccountInfoModal .gradeList input[type=checkbox] {
    visibility: hidden;
    position: absolute;
}

.EditAccountInfoModal .gradeList .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px solid rgb(var(--color-green));
}

.EditAccountInfoModal .gradeList.error .checkmark {
    border: 2px solid rgb(var(--color-red));
}

.EditAccountInfoModal .gradeList input:checked~.checkmark {
    background-color: white;
    z-index: 0;
}

.EditAccountInfoModal .gradeList .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.EditAccountInfoModal .gradeList input:checked~.checkmark:after {
    display: none;
}

.EditAccountInfoModal .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
}

.EditAccountInfoModal .cancel {
    background-color: transparent;
    color: rgb(var(--color-green));
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
}

.EditAccountInfoModal .submit {
    background-color: rgb(var(--color-green));
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    padding: 8px 32px;
}

.EditAccountInfoModal .userData.error {
    border: 2px solid rgb(var(--color-red));
}

.EditAccountInfoModal .errorMessage {
    color: rgb(var(--color-red));
    font-weight: bold;
    font-size: 13px;
}

.EditAccountInfoModal .gradeList.error .checkmark {
    border: 2px solid rgb(var(--color-red));
}

@media (max-width: 767px) {
    .EditAccountInfoModal .gradeList {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) {
    .EditAccountInfoModal .gradeList {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 992px) {
    .EditAccountInfoModal .gradeList {
        grid-template-columns: repeat(9, 1fr);
    }
}