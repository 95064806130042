.Navbar {
    position: sticky;
    top: 0;
    padding: 32px 0px;
    background-color: white;
    z-index: 1000;
}

.Navbar.CondensedNavbar {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 5px #ebebeb;
}

.Navbar .logo img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.Navbar.CondensedNavbar .logo img {
    width: auto;
    object-fit: unset;
}

.Navbar .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Navbar .navbar-item {
    color: black;
    font-family: 'Roboto Mono', monospace;
    cursor: pointer;
}

.Navbar .navbar-item.navbar-item-dropdown {
    margin: 0;
    padding-bottom: 8px;
}

.Navbar .navbar-item.arrow-down svg {
    transform: rotateX(180deg);
    transition: transform .2s;
}

.Navbar .navbar-item.arrow-up svg {
    transform: rotateX(0);
    transition: transform .2s;
}

.Navbar ul {
    display: flex;
    flex-direction: row;
    font-size: 17px;
    gap: 16px;
    flex: 1;
}

.Navbar .row {
    width: 100%;
}

.Navbar .menu {
    display: flex;
}

.NavbarUserActions {
    width: fit-content;
}

.NavbarUserActions input {
    width: 100%;
}

.Navbar .loginButton {
    color: rgb(var(--color-red));
    font-size: 14px;
    font-weight: 300;
    padding: 7px 0;
    font-family: 'Roboto Mono', monospace;
    cursor: pointer;
}

.Navbar .separator {
    height: 1px;
    width: 100%;
    background-color: rgb(var(--color-red));
}

.Navbar .dropdown-toggle {
    display: flex;
    width: 100%;
    background-color: unset !important;
    border: unset;
    color: rgb(var(--color-red)) !important;
    align-items: center;
    gap: 12px;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
}

.Navbar .dropdown-toggle span {
    width: 100%;
    text-align: start;
}

.Navbar .dropdown-menu {
    width: 100%;
}

.Navbar .postcardsDropdown .dropdown-menu {
    width: fit-content;
    border-radius: 0px;
    background-color: rgb(var(--color-cream));
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    padding: 12px;
}

.Navbar .postcardsDropdown.mobile .dropdown-menu {
    width: 100%;
}

.Navbar .dropdown-toggle:focus {
    outline: none;
    box-shadow: none !important;
    background-color: unset;
    color: rgb(var(--color-red));
}

.Navbar .postcardsDropdown .dropdown-item {
    border-bottom: 2px solid rgb(var(--color-yellow));
}

.Navbar .postcardsDropdown .dropdown-item:hover {
    background-color: rgb(var(--color-yellow));
}

.Navbar .socials a {
    color: rgb(var(--color-red));
}

.Navbar .socials {
    margin-top: 4px;
    justify-content: flex-end;
}

.Navbar .searchField {
    display: flex;
    padding: 8px 0px;
}

.Navbar .searchField button {
    color: rgb(var(--color-red));
    background-color: transparent;
    padding: 0px 8px;
    margin: 0;
}

.Navbar .searchField input {
    font-family: 'Roboto Mono', monospace
}

.Navbar .condensed li button,
.Navbar .condensed .NavbarAuthSection .loginButton,
.Navbar .condensed .NavbarAuthSection span {
    color: #e75a41;
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    margin: 0;
}

.Navbar .condensed .NavbarAuthSection {
    display: flex;
    align-items: center;
}

.Navbar .condensed .NavbarAuthSection h3 {
    display: flex;
}

.Navbar .condensed .NavbarAuthSection .loginButton {
    padding: 0;
}

.Navbar .condensed .actions {
    display: flex;
    gap: 8px;
    flex-direction: row;
}

.Navbar .condensed .actions .primaryContainer {
    display: flex;
}

.Navbar .condensed .actions li {
    display: flex;
    align-items: center;
}

.Navbar .condensed .condensedSeparator {
    color: #e75a41;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.Navbar .condensed .socials {
    margin-left: 16px;
    gap: 16px;
}

.Navbar.mobile {
    background-color: white !important;
}

.Navbar.mobile .navbar-brand {
    width: 150px;
}

.Navbar.mobile .navbar-toggler {
    border: none;
}

.Navbar.mobile .navbar-toggler .toggleMenu {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.Navbar.mobile .navbar-toggler .toggleMenu span {
    width: 24px;
    height: 3px;
    background-color: rgb(var(--color-blue-4));
}

.Navbar.mobile .navbar-toggler:focus {
    outline: none !important;
    box-shadow: none;
}

.Navbar.mobile .navbar-nav a {
    color: black !important;
    font-family: 'Roboto Mono', monospace;
}

.Navbar.mobile .navbar-nav .dropdown-toggle::after {
    display: none;
}

.Navbar.mobile .navbar-nav .postcardsMenuItem {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.Navbar.mobile .navbar-nav .postcardsMenuItem p {
    margin: 0;
}

.Navbar.mobile .navbar-nav .postcardsMenuItem.arrow-down svg {
    transform: rotateX(180deg);
    transition: transform .2s;
}

.Navbar.mobile .navbar-nav .postcardsMenuItem.arrow-up svg {
    transform: rotateX(0);
    transition: transform .2s;
}

@media only screen and (max-width: 767px) {
    .Navbar .logo img {
        margin-bottom: 32px;
    }

    .Navbar .condensed .actions {
        flex-direction: column;
    }
}

@media only screen and (max-width: 575px) {
    .NavbarUserActions {
        width: 100%;
        padding: 16px 16px 0;
    }

    .Navbar .condensed .actions {
        width: fit-content;
        flex-direction: row;
        float: right;
    }
}