.PostcardDetailsTags .row {
  gap: 8px;
  margin: 0;
}

.PostcardDetailsTags .col {
  display: inline-block;
  padding: 2px 4px;
  border: 1px solid rgb(var(--color-blue-4));
  color: rgb(var(--color-blue-4));
  font-size: 13px;
  font-family: 'Roboto Mono', monospace;
  flex: unset;
  max-width: fit-content;
  cursor: pointer;
}