.CouponElement {
  margin-bottom: 24px;
  font-family: "Roboto Mono", monospace;
}

.CouponElement .label {
  margin: 0;
  font-size: 15px;
  color: rgb(48, 49, 61);
}

.CouponElement .coupon_info {
  margin: 48px 0;
}

.CouponElement .inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 16px;
  border: 1px solid black;
}

.CouponElement .inputContainer.errorCoupon {
  border: 1px solid rgb(var(--color-red));
}

.CouponElement .inputContainer input {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgb(48, 49, 61);
}

.CouponElement .inputContainer button {
  background-color: transparent;
  color: rgb(var(--color-blue-4));
  font-weight: 500;
  margin: 0;
}

.CouponElement .inputContainer button:disabled {
  opacity: 0.5;
}

.CouponElement .error {
  display: block;
  margin: 0;
  color: rgb(var(--color-red));
  font-size: 14px;
}