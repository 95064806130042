.StoryPageHeader .category {
    padding: 4px 8px;
    display: contents;
}

.StoryPageHeader .category.col a {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.StoryPageHeader .category.col a {
    padding: 4px 16px 4px 0;
}

.StoryPageHeader .category.col:not(:first-of-type) a::before {
    content: "";
    width: 1px;
    height: 16px;
    margin-right: 16px;
    background-color: rgb(var(--color-grey));
}

.StoryPageHeader .share {
    flex: 6;
}

.StoryPageHeader h1 {
    font-size: 30px;
}

.StoryPageHeader .col a,
.StoryPageHeader .col span {
    background: none;
    padding: 0;
    box-shadow: none;
    color: rgb(var(--color-grey));
    font-size: 14px;
    padding: 0;
    font-weight: 400;
    margin: 0;
    font-family: 'Roboto Mono', monospace;
}