.ToolkitPageToolkitDescription {
  padding: 24px;
  height: 100%;
}

.ToolkitPageToolkitDescription h3 {
  font-size: 25px;
  margin: 30px 0 0;
}

.ToolkitPageToolkitDescription h3:after {
  background: white;
  width: 34px;
  height: 1px;
  content: "";
  display: block;
  margin: 16px 0;
}

.ToolkitPageToolkitDescription p {
  line-height: 32px;
  font-size: 20px;
  font-family: 'DM Sans', sans-serif;
}