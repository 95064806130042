.ConnectionGraph {
    display: flex;
    flex-direction: row;
}

.ConnectionGraph .title {
    font-family: "DM Sans", sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.ConnectionGraph .subtitle {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}