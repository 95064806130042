.UserSubscriptionHistory h3 {
  color: rgb(var(--color-blue-4));
  font-size: 15px !important;
  text-transform: uppercase;
  padding-top: 16px;
}

.UserSubscriptionHistory p {
  margin-bottom: 4px;
}

.UserSubscriptionHistory p:last-of-type {
  margin-bottom: 24px;
}

.UserSubscriptionHistory .status {
  margin: 16px 0;
}

.UserSubscriptionHistory .status span {
  font-weight: 500;
}