.PostcardsSearchListContent.header {
    display: flex;
    border: none;
    padding: 32px;
    padding-bottom: 12px;
}

.PostcardsSearchListContent.header .row {
    display: flex;
    width: 100%;
    margin: 0 32px 0 0;
    align-items: center;
}

.PostcardsSearchListContent.header h2 {
    color: #e75a41;
    font-weight: 300;
    font-size: 30px;
}

.PostcardsSearchListContent.header .searchInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e75a41;
    gap: 16px;
}


.PostcardsSearchListContent.header .searchInput input {
    width: 100%;
    position: relative;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    height: 32px;
}

.PostcardsSearchListContent.header .searchInput button {
    background: none;
    color: #e75a41;
}

.PostcardsSearchListContent.header svg {
    background: none;
    color: #e75a41;
    height: 18px;
    width: 18px;
}


.PostcardsSearchListContent.header .closeContainer {
    display: flex;
    justify-content: flex-end;
}

.PostcardsSearchListContent.header .closeSearchModal {
    background-color: transparent;
}

.PostcardsSearchListContent.body {
    padding: 0 32px;
}

.PostcardsSearchListContent.footer {
    border: none;
    justify-content: center;
}

.PostcardsSearchListContent.body .noResults {
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.PostcardsSearchListContent.body .noResults h4 {
    height: fit-content;
    width: fit-content;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;
}