.RichText ul li:before {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #000;
    content: "";
    position: absolute;
    left: 0;
    top: 20px;
}

.RichText ul li {
    padding-top: 10px;
    font-size: 13px;
    position: relative;
    padding-left: 25px;
}

.RichText p {
    font-size: 15px;
}