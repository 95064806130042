.ChildStyleContent .nav-tabs {
    margin-bottom: 0 !important;
    border-bottom: unset;
}

.ChildStyleContent .tab-content {
    background-color: white;
    border: none;
    box-shadow: 0 0 6px #949494;
    padding: 48px;
}

.ChildStyleContent .nav-item {
    flex: 1;
}

.ChildStyleContent .nav-item button,
.ChildStyleContent .nav-item.disabled button {
    width: 100%;
    background: rgb(var(--color-green));
    color: white;
    padding: 8px;
    font-size: 13px;
    height: 100%;
    border-radius: 26px 20px 0px 0px;
    line-height: 16px;
}

.ChildStyleContent .nav-item.disabled {
    height: 100%;
}

.ChildStyleContent .nav-item.disabled button {
    height: 100%;
    opacity: 0.8;
    cursor: default;
}

.ChildStyleContent .nav-item.active button {
    background-color: white;
    color: black;
}

.ChildStyleContent .PageModuleSaleContent {
    margin-top: 64px;
}