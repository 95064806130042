.SubscriptionUserSignedIn {
  padding: 16px;
}

.SubscriptionUserSignedIn .dropdown {
  font-family: 'Roboto Mono', monospace;
  background: none;
  color: black;
  border: 1px solid black;
  padding: 4px 12px;
  width: 100%;
  margin-bottom: 32px;
  cursor: pointer;
}

.SubscriptionUserSignedIn .dropdown p {
  margin: 0;
}

.SubscriptionUserSignedIn .dropdown .selected {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.SubscriptionUserSignedIn .dropdown .dropdown-menu {
  width: 100%;
  word-break: break-all;
  white-space: normal;
  border: 1px solid black;
  border-radius: 0;
  box-shadow: 0px 6px 12px lightgray;
}

.SubscriptionUserSignedIn .dropdown .dropdown-item {
  word-break: break-all;
  white-space: normal;
}

.SubscriptionUserSignedIn .navbar-item {
  width: 100%;
}