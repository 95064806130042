.EmpowermentGraph {
    display: flex;
    flex-direction: row;
}

.EmpowermentGraph .title {
    font-family: "DM Sans", sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.EmpowermentGraph .subtitle {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}