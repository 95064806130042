.GetInTouchModal .GetInTouch {
    padding: 48px 32px;
}

.GetInTouchModal .closeBtn {
    position: absolute;
    right: 48px;
    cursor: pointer;
}

.GetInTouch {
    background-color: rgb(var(--color-light-pink));
}

.GetInTouch h3 {
    color: white;
    font-size: 30px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 600;
}

.GetInTouch input,
.GetInTouch textarea {
    background: none;
    padding: 12px;
    color: white;
    border-bottom: 2px solid white;
    width: 100%;
    font-size: 20px;
    font-family: 'Roboto Mono', monospace;
    margin-bottom: 12px;
}

.GetInTouch textarea {
    margin-top: 32px;
    border: 2px solid white;
    height: 150px;
    resize: none;
}

.GetInTouch .btnSubmit {
    min-width: 160px;
    border: none;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
    background: var(--img-right-arrow-white) no-repeat right center;
    transition: all 0.5s;
}

.GetInTouch ::placeholder {
    color: white;
}

.GetInTouch ::-ms-input-placeholder {
    color: white;
}

.GetInTouch .errorMessage {
    font-weight: bold;
}