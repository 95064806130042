.GaugeChart {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GaugeChart canvas {
    position: absolute;
}

.GaugeChart canvas:not(:first-child) {
    padding: 0.8vw;
}

@media only screen and (max-width: 600px) {
    .GaugeChart {
        height: 300px;
    }
}

@media only screen and (min-width: 600px) {
    .GaugeChart {
        height: 300px;
    }
}

@media only screen and (min-width: 768px) {
    .GaugeChart {
        height: 250px;
    }
}

@media only screen and (max-width: 767px) {
    .GaugeChart canvas:not(:first-child) {
        padding: 2vw;
    }
}

@media only screen and (min-width: 992px) {
    .GaugeChart {
        height: 300px;
    }
}

@media only screen and (min-width: 1200px) {
    .GaugeChart {
        height: 400px;
    }
}