.PasswordResetPage {
  padding: 48px 0 72px 0;
}

.PasswordResetPage .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PasswordResetPage .LoginModal {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 5px 10px grey;
}

.PasswordResetPage .errorMessage {
  color: black;
  margin-bottom: 8px;
}