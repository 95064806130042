.PageModuleSaleContent .content {
    font-family: "DM Sans", sans-serif !important;
}

.PageModuleSaleContent .saleView {
    display: flex;
    flex-direction: column;
    background: rgb(var(--color-green));
    color: white;
    padding: 24px;
    position: relative;
}

.PageModuleSaleContent .saleView>h3 {
    line-height: 1.3;
    font-size: 36px;
    text-align: center;
}

.PageModuleSaleContent .feature {
    display: flex;
    gap: 16px;
}

.PageModuleSaleContent .feature svg {
    padding: 3px;
    color: rgb(var(--color-yellow));
}

.PageModuleSaleContent .feature p {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
}

.PageModuleSaleContent .subscriptionOptions a {
    background: var(--img-right-arrow-white) no-repeat right center rgb(var(--color-red));
    margin-bottom: 15px;
    background-position: 93% 50%;
    display: block;
    color: white;
    border-radius: 5px;
    padding: 15px 40px;
}

.PageModuleSaleContent .subscriptionOptions a span {
    font-size: 14px;
}

.PageModuleSaleContent .subscriptionOptions a h4 {
    font-size: 20px;
    color: white;
    font-weight: 500;
    padding: 0px 0;
}

.PageModuleSaleContent .subscriptionOptions a:last-child {
    background: var(--img-right-arrow-white) no-repeat right center rgb(var(--color-light-pink));
    background-position: 93% 50%;
}