.ToolPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    font-family: 'Libre Baskerville', serif;
    padding: 16px;
}

.ToolPreview img {
    height: 240px !important;
}

.swiper-slide {
    height: auto;
    padding: 16px;
}

.ToolPreview .title {
    margin: 16px 0;
    font-size: 25px;
    font-family: 'Libre Baskerville', serif;
    line-height: 30px;
    text-align: start;
    color: black;
}

.ToolPreview .title:hover {
    color: rgb(var(--color-blue-4));
}

.ToolPreview .RichText {
    text-align: start;
    color: rgb(var(--color-grey));
    line-height: 23px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
}

.ToolPreview .RichText p:last-child {
    margin-bottom: 4px;
}