.ParentQuizPage {
    background-color: rgb(var(--color-cream));
}

.ParentQuizPage .container {
    padding: 32px;
}

.ParentQuizPage .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ParentQuizPage .description h1 {
    font-size: 28px;
    margin-bottom: 24px;
    color: rgb(var(--color-green));
}

.ParentQuizPage .ConversationalForm .actions:not(.lastPage) {
    margin-top: -100px;
}

@media (min-width: 768px) {
    .ParentQuizPage .quiz {
        padding-left: 48px;
    }
}