.ParentQuizGetResults {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ParentQuizGetResults h3 {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    color: rgb(var(--color-green));
    line-height: 25px;
}

.ParentQuizGetResults .userDataContainer {
    display: flex;
    flex-direction: column;
}

.ParentQuizGetResults .userData {
    border: 2px solid rgb(var(--color-green));
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin-top: 8px;
    background: none;
    color: rgb(var(--color-green));
}

.ParentQuizGetResults .userData.error {
    border: 2px solid rgb(var(--color-red));
}

.ParentQuizGetResults .errorMessage {
    color: rgb(var(--color-red));
    font-weight: bold;
    font-size: 13px;
}

.ParentQuizGetResults .gradeListHeader {
    color: rgb(var(--color-green));
    font-size: 16px;
    padding: 0;
    margin: 12px 0 4px 0;
    font-family: "DM Sans", sans-serif;
}

.ParentQuizGetResults .gradeList {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
}

.ParentQuizGetResults .gradeList .grade {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.ParentQuizGetResults .gradeList label {
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 4px;
    text-align: center;
    color: rgb(var(--color-green));
}

.ParentQuizGetResults .gradeList input[type=checkbox] {
    visibility: hidden;
    position: absolute;
}

.ParentQuizGetResults .gradeList .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px solid rgb(var(--color-green));
}

.ParentQuizGetResults .gradeList.error .checkmark {
    border: 2px solid rgb(var(--color-red));
}

.ParentQuizGetResults .gradeList input:checked~.checkmark {
    background-color: white;
    z-index: 0;
}

.ParentQuizGetResults .gradeList .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ParentQuizGetResults .gradeList input:checked~.checkmark:after {
    display: none;
}

.ParentQuizGetResults form {
    display: flex;
    flex-direction: column;
}

.ParentQuizGetResults .submit {
    border: none;
    font-size: 20px;
    cursor: pointer;
    background: none;
    font-family: 'Roboto', sans-serif;
    padding-top: 8px;
    color: rgb(var(--color-green));
    align-self: flex-end;
}

.ParentQuizGetResults .gradeListContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.ParentQuizGetResults .gradeListContainer h4 {
    width: 72px;
    color: rgb(var(--color-green));
    margin-right: 16px;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
}

.ParentQuizGetResults .gradeListContainer div {
    flex: 1;
    margin: 0;
}

@media (max-width: 767px) {
    .ParentQuizGetResults .gradeList {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) {
    .ParentQuizGetResults .gradeList {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 992px) {
    .ParentQuizGetResults .gradeList {
        grid-template-columns: repeat(9, 1fr);
    }
}