.RecentPostcards {
    padding: 48px 0;
}

.RecentPostcards h2 {
    margin-bottom: 30px;
    color: #0f64e6;
    font-size: 18px;
    text-transform: uppercase;
    position: relative;
    font-family: "DM Sans", sans-serif;
}

.RecentPostcards .swiperContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32;
}

.RecentPostcards .swiper {
    margin: 0 24px;
}

.RecentPostcards .swiperContainer .custom-swiper-button-next,
.custom-swiper-button-prev {
    position: relative;
}