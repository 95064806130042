.ParentQuizResultsContent {
    background-color: rgb(var(--color-cream));
    padding: 70px 0;
}

.ParentQuizResultsContent h2 {
    font-size: 25px;
}

.ParentQuizResultsContent .RichText p:first-of-type {
    line-height: 28px;
    font-size: 15px;
    margin-bottom: 25px;
    font-family: 'DM Sans', sans-serif;
}

.ParentQuizResultsContent .PageModuleSaleContent {
    margin-top: 70px;
}

.ParentQuizResultsContent .subscribe {
    margin-bottom: 48px;
}