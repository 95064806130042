.PostcardsByGradePage {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.PostcardsByGradePage .filterContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 32px;
}

.PostcardsByGradePage .filterContainer .filterItem {
  cursor: pointer;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 14px;
}

.PostcardsByGradePage .filterContainer .filterItem.active {
  color: rgb(var(--color-blue-4));
}