.YourPostcardsContent .col {
    padding: 16px;
}

.YourPostcardsContent .searchContainer {
    background-color: rgb(var(--color-cream));
    padding: 16px;
    border-radius: 8px;
    margin: 16px;
}

.YourPostcardsContent .searchInput {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: white;
    border: 1px solid rgb(var(--color-grey));
    font-size: 17px;
    width: 100%;
    border-radius: 4px;
}

.YourPostcardsContent .searchInput input {
    width: 100%;
    padding: 6px 12px;
    border-radius: 4px;
}

.YourPostcardsContent .searchInput button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 12px;
}

.YourPostcardsContent .searchInput img {
    height: 12px;
}

.YourPostcardsContent .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.YourPostcardsContent .radio input {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.YourPostcardsContent .radio label {
    cursor: pointer;
}

.YourPostcardsContent .emptyResults h3 {
    text-align: center;
    padding: 80px 0;
    font-size: 16px;
}