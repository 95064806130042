.KidQuizGradeSelection {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.KidQuizGradeSelection h3 {
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: rgb(var(--color-green));
  line-height: 25px;
}

.KidQuizGradeSelection .gradeList {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}

.KidQuizGradeSelection .gradeList .grade {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.KidQuizGradeSelection .gradeList label {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 4px;
  text-align: center;
  color: rgb(var(--color-green));
}

.KidQuizGradeSelection .gradeList input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}

.KidQuizGradeSelection .gradeList .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid rgb(var(--color-green));
}

.KidQuizGradeSelection .gradeList input:checked~.checkmark {
  background-color: white;
  z-index: 0;
}

.KidQuizGradeSelection .gradeList .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.KidQuizGradeSelection .gradeList input:checked~.checkmark:after {
  display: none;
}

.KidQuizGradeSelection .updateGrades {
  color: black;
}

.KidQuizGradeSelection .updateGrades a {
  color: black;
  text-decoration: underline;
}

.KidQuizGradeSelection .next {
  border: none;
  font-size: 20px;
  cursor: pointer;
  background: none;
  font-family: 'Roboto', sans-serif;
  padding-top: 8px;
  color: rgb(var(--color-green));
  align-self: flex-end;
}

.KidQuizGradeSelection .next:disabled {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 767px) {
  .KidQuizGradeSelection .gradeList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 768px) {
  .KidQuizGradeSelection .gradeList {
    grid-template-columns: repeat(6, 1fr);
  }
}