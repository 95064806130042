footer {
    clear: both;
    padding: 30px 0;
    background: #efefef;
    text-align: center;
}

footer li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 16px;
}

footer li a {
    color: #000;
    font-family: "Roboto Mono", monospace;
}

footer li+li:before {
    content: "|";
    color: #000;
    padding: 0 25px;
}

footer li a:hover {
    color: #0f64e6;
}

footer p {
    color: #000;
    padding-top: 20px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
}

footer p a {
    color: #0f64e6;
    text-decoration: underline;
    font-weight: 400;
}

footer .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}