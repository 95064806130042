.PageModuleTestimonials .chat-box {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100%;
    width: 100%;
    color: #fff;
    padding: 70px 90px 40px 90px;
    min-height: 260px;
}

.PageModuleTestimonials .chat-box p {
    font-size: 16px;
    color: #fff;
    font-family: 'Libre Baskerville', serif;
    line-height: 1.5;
    font-style: italic;
    text-align: center;
}

.PageModuleTestimonials .chat-box span {
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 0px;
    text-align: center;
    margin-top: 10px;
    color: #FFF !important;
}