.AdminIntroCyclePage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        background-color: rgb(var(--color-cream));
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 16px;
        margin: 4px;
        min-width: 320px;
        text-align: center;
    }

    .container .title {
        font-size: 16px;
        font-weight: 600;
        font-family: 'DM Sans', sans-serif;
    }

    .container .email-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 24px;
    }

    .container .email-container .email-item {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
        margin: 0;
    }

    .container .email-container .email-item p {
        margin: 0;
    }

    .container .email-container .email-item input {
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid rgb(var(--color-green));
    }

    .container .send-container {
        width: 100%;
    }

    .container .send-container .send-button {
        width: 100%;
        font-size: 14px;
    }

    .intro-cycle-dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 288px;
        background: white !important;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid rgb(var(--color-green)) !important;
        color: black !important;
    }

    .intro-cycle-dropdown span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}