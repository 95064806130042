.PostcardsPage {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.PostcardsPage .ageGroupItemContainer {
    padding: 16px;
}

.PostcardsPage .ageGroupItemContainer .ageGroupItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 32px;
    color: black;
    border-radius: 32px;
    cursor: pointer;
}

.PostcardsPage .ageGroupItemContainer .ageGroupItem img {
    height: 240px;
    object-fit: cover;
}

.PostcardsPage .ageGroupItemContainer .ageGroupItem h3 {
    margin-top: 32px;
}

.PostcardsPage .RecentPostcards {
    background-color: rgb(var(--color-cream));
}

.PostcardsPage .RecentPostcards .swiper-slide {
    background-color: transparent;
}

@media only screen and (max-width: 992px) {
    .PostcardsPage .ageGroupItemContainer .ageGroupItem img {
        height: 50%;
        object-fit: cover;
    }
}