.CustomText {
  padding: 0;
  margin: 0;
}

.CustomText.headerLarge {
  font-family: 'Libre Baskerville';
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .CustomText.headerLarge {
    font-size: 25px;
  }

  .CustomText.headerNormal {
    font-size: 21px;
  }
}

@media only screen and (min-width: 600px) {
  .CustomText.headerLarge {
    font-size: 25px;
  }

  .CustomText.headerNormal {
    font-size: 21px;
  }
}

@media only screen and (min-width: 768px) {
  .CustomText.headerLarge {
    font-size: 30px;
  }

  .CustomText.headerNormal {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) {
  .CustomText.headerLarge {
    font-size: 35px;
  }

  .CustomText.headerNormal {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .CustomText.headerLarge {
    font-size: 40px;
  }

  .CustomText.headerNormal {
    font-size: 32px;
  }
}