.EmailWrapperItem {
    display: flex;
    flex-direction: column;
    background-color: rgb(var(--color-cream));
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
    margin: 4px;
}

.EmailWrapperItem h5 {
    font-size: 13px;
    font-family: "DM Sans", sans-serif;
}

.EmailWrapperItem h4,
.EmailWrapperItem .viewPostcard {
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}

.EmailWrapperItem .info {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.EmailWrapperItem .info .item {
    flex: 1;
}

.EmailWrapperItem .gradesLabel,
.EmailWrapperItem .postcardLabel {
    margin-top: 24px;
}

.EmailWrapperItem .grades {
    gap: 16px;
}

.EmailWrapperItem button {
    margin-top: 32px;
}