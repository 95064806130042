.SummaryTab {
    display: flex;
    flex-direction: column;
}

.SummaryTab .retakeSurvey {
    align-self: flex-end;
    margin: 32px 0 64px;
}

.SummaryTab .ParentPersona {
    margin-top: 48px;
}