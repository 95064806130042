.PageModule {
    padding: 48px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.swiper-button-next {
    color: rgb(var(--color-grey));
}

.swiper-button-prev {
    color: rgb(var(--color-grey));
}

.slider-image {
    padding: 32px;
}