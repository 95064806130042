.FilteredDataView {
  margin-bottom: 48px;
}

.FilteredDataView .totalResults {
  font-size: 24px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  color: rgb(var(--color-red));
  border-bottom: 1px solid rgb(var(--color-red));
}

.FilteredDataView .PostcardPreview {
  padding: 0;
  padding-top: 32px;
}

.FilteredDataView .PostcardPreview {
  padding: 0;
  padding-top: 32px;
}

.FilteredDataView .PostcardPreview img {
  max-width: 100%;
  height: auto !important;
  padding: 0;
}

.FilteredDataView .Pagination {
  margin: 32px 0 0;
}

.FilteredDataView .noResults {
  color: rgb(var(--color-grey));
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  line-height: 23px;
  margin: 24px 0;
  text-align: center;
}