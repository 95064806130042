.UserDashboard h2 {
    font-size: 26px;
    margin-bottom: 24px;
}

.UserDashboard .infoBox {
    background: #FFF;
    padding: 25px;
    border: 1px solid #e1e1e1;
    position: relative;
    margin-top: 20px;
}

.UserDashboard .infoBox h3 {
    font-size: 20px;
    margin-bottom: 20px;
}

.UserDashboard .infoBox.Grades p {
    font-weight: 600;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    line-height: 28px;
    margin-bottom: 4px;
}

.UserDashboard .infoBox.PersonalInfo h3 {
    margin-bottom: 24px;
}

.UserDashboard .infoBox.PersonalInfo p {
    color: #000;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    margin-bottom: 4px;
}

.UserDashboard .infoBox.PersonalInfo p:last-of-type {
    margin-bottom: 24px;
}