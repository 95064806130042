.AboutPageContent .subtitle {
    font-size: 18px;
    color: black;
    padding-bottom: 32px;
    font-family: "DM Sans", sans-serif;
    display: block;
    text-transform: uppercase;
}

.AboutPageContent .title h2 {
    font-size: 25px;
}