.ForgotPassword {
  background-color: rgb(var(--color-green));
  padding: 32px;
}

.ForgotPassword .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ForgotPassword .closeLoginModal {
  background: none;
  color: white;
}

.ForgotPassword h3 {
  color: white;
  font-size: 30px;
}

.ForgotPassword .forgotPasswordForm {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ForgotPassword .forgotPasswordForm input {
  background: none;
  padding: 10px;
  color: white;
  border-bottom: 2px solid white;
  width: 100%;
  font-size: 20px;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 10px;
}

.ForgotPassword ::placeholder {
  color: white;
  opacity: 0.8;
}

.ForgotPassword .submitBtn {
  width: fit-content !important;
  border-bottom: none !important;
  font-size: 24px !important;
  padding: 0px !important;
  background: var(--img-right-arrow-white) no-repeat right center !important;
  background-position: 85% center;
  padding-right: 32px !important;
  margin-top: 32px;
}

.ForgotPassword .errorMessage {
  align-self: center;
  color: white;
  font-weight: bold;
}