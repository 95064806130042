.AdminPageContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.AdminPageContent .content {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  gap: 24px;
}

.AdminPageContent .pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: sticky;
  bottom: 0;
}

.AdminPageContent .noResults {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.AdminPageContent .noResults h4 {
  font-size: 20px;
}

.AdminPageContent .loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(5px);
  gap: 16px;
}

.AdminPageContent .loading h4 {
  font-size: 20px;
}