.PostcardDetailsLoginSection {
    box-shadow: 0px 7px 15px lightgray;
}

.PostcardDetailsLoginSection p {
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection {
    background-color: rgb(var(--color-green));
    padding: 32px;
    box-shadow: 0px 7px 15px lightgray;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection h3 {
    color: white;
    font-size: 30px;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .RichText {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: white;
    margin-top: 24px;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .RichText h3 {
    font-size: 25px;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .subscriptionOptions a {
    background: var(--img-right-arrow-white) no-repeat right center rgb(var(--color-red));
    margin-bottom: 15px;
    background-position: 93% 50%;
    display: block;
    color: white;
    border-radius: 5px;
    padding: 15px 40px;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .subscriptionOptions a:last-child {
    background: var(--img-right-arrow-white) no-repeat right center rgb(var(--color-light-pink));
    background-position: 93% 50%;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .subscriptionOptions a span {
    font-size: 14px;
}

.PostcardDetailsLoggedInNoActiveSubscriptionSection .subscriptionOptions a h4 {
    font-size: 20px;
    color: white;
    font-weight: 500;
    padding: 0px 0;
}