.PostcardPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    font-family: 'Libre Baskerville', serif;
    padding: 16px;
}

.PostcardPreview .header {
    cursor: pointer;
}

.PostcardPreview img {
    height: 240px !important;
}

.swiper-slide {
    height: auto;
    padding: 16px;
}

.PostcardPreview .title {
    margin: 16px 0;
    font-size: 25px;
    font-family: 'Libre Baskerville', serif;
    line-height: 30px;
    text-align: start;
    color: black;
}

.PostcardPreview .title:hover {
    color: rgb(var(--color-blue-4));
}

.PostcardPreview .RichText {
    text-align: start;
    color: rgb(var(--color-grey));
    line-height: 23px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
}

.PostcardPreview .RichText p:last-child {
    margin-bottom: 4px;
}

.PostcardPreview .readMore {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(var(--color-blue-4));
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    gap: 4px;
    cursor: pointer;
}

.PostcardPreview .readMore p {
    margin: 0;
}

.PostcardPreview .tags {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
    margin-top: 16px;
}

.PostcardPreview .tag {
    color: rgb(var(--color-blue-4));
    font-size: 11px;
    font-family: 'Roboto Mono', monospace;
    display: inline-block;
    cursor: pointer;
}

.PostcardPreview .divider {
    width: 1px;
    height: 12px;
    background-color: rgb(var(--color-blue-4));
    margin-bottom: 1px;
}