.KidQuizUserSignedOut {
    background-color: rgb(var(--color-cream));
    padding: 120px 0;
    color: rgb(var(--color-green));
}

.KidQuizUserSignedOut .container {
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
}

.KidQuizUserSignedOut h4 {
    font-size: 16px;
}