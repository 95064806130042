.RecentPostcardsSection {
  display: flex;
  flex-direction: column;
}

.RecentPostcardsSection h3 {
  font-size: 18px;
  color: rgb(var(--color-blue-4));
  text-transform: uppercase;
  padding: 48px 0 16px;
  font-family: "DM Sans", sans-serif;
}

.RecentPostcardsSection .postcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  border-top: 1px dashed rgb(var(--color-blue-1));
  font-size: 16px;
  font-family: 'Libre Baskerville', serif !important;
  list-style: none !important;
  margin-left: 0 !important;
}

.RecentPostcardsSection .postcard {
  color: #000;
}

.RecentPostcardsSection .postcard:hover {
  color: rgb(var(--color-blue-4));
}

.RecentPostcardsSection .postcard p {
  margin: 8px 0 0;
}