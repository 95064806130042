.AboutPageContentTeamSection .SectionItem {
  margin-top: 48px;
}

.AboutPageContentTeamSection .SectionItem img {
  width: 210px;
  height: 210px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 32px;
}

.AboutPageContentTeamSection .SectionItem h3 {
  color: black;
  font-size: 22px;
  margin-bottom: 12px;
}