.PostcardsPageAllPostcards {
  margin-bottom: 48px;
}

.PostcardsPageAllPostcards .gridItemContainer {
  display: flex;
  align-items: center;
}

.PostcardsPageAllPostcards .title {
  text-transform: uppercase;
  font-size: 13px;
  color: rgb(var(--color-grey));
  font-weight: 500;
  padding-bottom: 4px;
  font-family: 'DM Sans', sans-serif;
}

.PostcardsPageAllPostcards .postcardLink {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  color: rgb(var(--color-blue-4));
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 8px;
}

.PostcardsPageAllPostcards .postcardLink.last {
  margin-bottom: 8px;
}

.PostcardsPageAllPostcards .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
}

.PostcardsPageAllPostcards .header button {
  background-color: transparent;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  color: rgb(var(--color-blue-4));
}

.PostcardsPageAllPostcards h2 {
  text-transform: uppercase;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  color: rgb(var(--color-blue-4));
  padding-top: 48px;
  font-weight: 500;
}

.PostcardsPageAllPostcards h3 {
  width: 100%;
}

.PostcardsPageAllPostcards .divider {
  width: 100%;
  height: 1px;
  background-color: rgb(var(--color-orange));
  margin-top: 16px;
  margin-bottom: 16px;
}