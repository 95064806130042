.ConversationalForm .content {
    overflow: hidden;
}

.ConversationalForm .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    padding-bottom: 16px;
}

.ConversationalForm .actions button {
    color: rgb(var(--color-green));
    background-color: transparent;
    font-size: 18px;
}

.ConversationalForm .actions div {
    display: flex;
    width: 100%;
}

.ConversationalForm .actions div:first-of-type {
    justify-content: flex-end;
}

.ConversationalForm .pageIndicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: rgb(var(--color-green));
}

.ConversationalForm .pageIndicator span {
    font-weight: 500;
}

.ConversationalForm .nextBtnContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
    justify-content: flex-start !important;
}

.ConversationalForm .nextBtnContainer span {
    color: rgb(var(--color-green)) !important;
    font-size: 10px;
}