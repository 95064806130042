.CustomButton {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    font-family: "Roboto Mono", monospace;
    border-radius: 30px;
    padding: 12px 24px;
    margin: 6px;
}

.CustomButton.green {
    background: rgb(var(--color-green));
    color: white;
    box-shadow: 3px 3px 3px rgba(var(--color-orange-half-opacity));
}

.CustomButton.green:hover {
    background: rgb(var(--color-blue-4));
}

.CustomButton.white {
    background: white;
    color: rgb(var(--color-blue-4));
    box-shadow: 3px 3px 3px rgba(var(--color-orange-half-opacity));
}

.CustomButton.white:hover {
    background: rgb(var(--color-blue-4));
    color: white;
}

.CustomButton.disabled {
    cursor: default;
    opacity: 0.4;
}

.CustomButton.green.disabled:hover {
    background: rgb(var(--color-green));
    cursor: default;
    opacity: 0.4;
}