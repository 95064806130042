@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
}

body,
html,
#root {
    margin: 0px;
    padding: 0px;
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    color: #0c0c0c;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scroll-behavior: smooth !important;

    /* #0f64e6 */
    --color-blue-4: 15, 100, 230;
    /* #4a91c9 */
    --color-blue-3: 74, 145, 201;
    /* #87b1f3 */
    --color-blue-2: 135, 176, 243;
    /* #8fc0ed */
    --color-blue-1: 143, 192, 237;
    /* #fdf6dc */
    --color-cream: 253, 246, 220;
    /* #1c9970 */
    --color-green: 28, 153, 112;
    /* #6f6f6f */
    --color-grey: 111, 111, 111;
    /* f099a8 */
    --color-light-pink: 240, 153, 168;
    /* fe895a */
    --color-orange: 254, 137, 90;
    /* fe895a */
    --color-orange-half-opacity: 254, 137, 90, 0.5;
    /* #e75a41 */
    --color-red: 231, 90, 65;
    /* #eb6e91 */
    --color-pink: 235, 110, 145;
    /* #f9d36f */
    --color-yellow: 249, 211, 111;
}

a {
    text-decoration: none;
}

aside,
details,
figcaption,
figure,
ins,
summary,
mark,
title,
video,
footer,
header,
hgroup,
nav,
section,
article {
    display: block;
}

ul,
ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

input,
button {
    outline: none;
    border: none;
}

button {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    outline: none;
    border: none;
    outline: none;
}

button:focus {
    outline: none;
    border: none;
}

img {
    border: none;
}

figure {
    margin: 0;
}

b,
strong {
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-family: "Libre Baskerville", serif;
}

h1 {
    font-size: 50px;
}

h4 {
    line-height: 20px;
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
}

h5 {
    font-size: 15px;
    line-height: 28px;
}

h6 {
    font-size: 12px;
    line-height: 22px;
    font-family: "DM Sans", sans-serif;
}

p {
    line-height: 28px;
}

.bold {
    font-weight: 600;
}

.modal-content {
    border: none;
    border-radius: 0;
}

.sectionHeader {
    color: rgb(var(--color-blue-4));
    font-size: 14px;
    text-transform: uppercase;
    font-family: "DM Sans", sans-serif;
    margin: 32px 0 16px;
}

.sectionHeader:before {
    content: "";
    background: var(--border) 0 center no-repeat;
    width: 32px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
}

.sectionHeader:after {
    content: "";
    background: var(--border) 0 center no-repeat;
    width: 32px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
}

.img-fluid {
    object-fit: contain
}

.tooltip {
    padding: 0;
}

.accordion {
    margin: 16px 0;
}

.accordion-item {
    border: none;
}

.accordion-header {
    border-top: 1px solid black;
}

.accordion-item:first-of-type .accordion-header {
    border: none;
}

.accordion-header button {
    padding: 24px;
    background: none;
}

.accordion-header button:focus {
    outline: none;
    box-shadow: none;
}

.accordion-collapse {
    border: none;
}

.accordion-button {
    color: rgb(var(--color-blue-4));
    font-size: 18px;
}

.accordion-button:not(.collapsed) {
    color: rgb(var(--color-blue-4));
    background-color: transparent;
    box-shadow: none;
}