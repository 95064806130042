.SubscriptionPage {
    height: fit-content;
    background-color: rgb(var(--color-yellow));
    min-height: 100%;
}

.SubscriptionPage .container {
    padding: 48px;
    background-color: rgb(var(--color-yellow));
}

.SubscriptionPage .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 32px;
}

.SubscriptionPage .RichText h2 span {
    font-weight: normal;
    margin-bottom: 24px;
}

.SubscriptionPage .RichText h2 {
    margin-bottom: 24px;
}

.SubscriptionPage .RichText p {
    margin: 0;
}

.SubscriptionPage .header .close {
    position: absolute;
    right: 0;
    top: 16px;
}

.SubscriptionPage .subscription {
    height: 100%;
    background-color: white;
}

@media only screen and (max-width: 767px) {
    .SubscriptionPage .container {
        padding: 16px;
    }

    .SubscriptionPage .row {
        gap: 32px;
    }

    .SubscriptionPage .header .logo {
        width: 200px;
    }
}