.AboutPageContentWhatSection .SectionItem {
  margin-top: 24px;
}

.AboutPageContentWhatSection .SectionItem .RichText h3:first-of-type {
  font-size: 16px;
  color: rgb(var(--color-blue-4));
  padding: 16px 0 8px;
}

.AboutPageContentWhatSection .SectionItem .RichText p {
  color: dimgray;
  line-height: 20px;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
}