.AdminPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.AdminOption {
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--color-cream));
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin: 4px;
  min-width: 320px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
}

.welcome {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.description {
  font-size: 16px;
  margin: 0;
  margin-top: 16px;
}