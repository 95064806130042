.ChildStyleItemTab {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.ChildStyleItemTab .RichText h3:first-of-type {
  margin-bottom: 16px;
}

.ChildStyleItemTab .RichText p:last-child {
  margin-bottom: 0;
}

.ChildStyleItemTab .section a:first-of-type {
  float: right;
  margin-top: 16px;
}