.KidQuizGetResults {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.KidQuizGetResults h3 {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    color: rgb(var(--color-green));
    line-height: 25px;
}

.KidQuizGetResults .submit {
    border: none;
    font-size: 20px;
    cursor: pointer;
    background: none;
    font-family: 'Roboto', sans-serif;
    padding-top: 8px;
    color: rgb(var(--color-green));
    align-self: flex-end;
}