.DefaultErrorMessage {
    background-color: rgb(var(--color-cream));
    padding: 200px 0px;
}

.DefaultErrorMessage .RichText {
    color: rgb(var(--color-green));
}

.DefaultErrorMessage .RichText h2:first-of-type {
    margin-bottom: 48px;
}

.DefaultErrorMessage .RichText h2:nth-of-type(2) {
    margin-bottom: 16px;
}