.QuizItem {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.QuizItem h3 {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    color: rgb(var(--color-green));
    line-height: 25px;
}

.QuizItem .optionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.QuizItem .quizOption {
    color: rgb(var(--color-green));
    font-size: 14px;
}