.LoadingView {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoadingView .spinner-border {
  color: rgb(var(--color-grey));
}

.LoadingView span {
  margin-top: 16px;
  color: rgb(var(--color-grey));
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
}