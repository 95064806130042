.CustomSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background: rgb(var(--color-blue-1));
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 32px;
}

.CustomSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 25px;
    background: rgb(var(--color-blue-4));
    cursor: pointer;
    border-radius: 32px;
}

.CustomSlider::-moz-range-thumb {
    width: 12px;
    height: 25px;
    background: rgb(var(--color-blue-4));
    cursor: pointer;
    border-radius: 32px;
}