.PostcardDetailsUsefulTools .row {
  padding: 16px 32px;
}

.PostcardDetailsUsefulTools .row {
  gap: 24px;
}

.PostcardDetailsUsefulTools .col {
  flex: unset;
  max-width: fit-content;
  padding: 0;
}

.PostcardDetailsUsefulTools .col a {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.PostcardDetailsUsefulTools .col p {
  color: rgb(var(--color-blue-4));
  font-size: 16px;
  font-family: 'Roboto Mono', monospace;
  margin-top: 12px;
  margin-bottom: 0;
}

.PostcardDetailsUsefulTools .col img {
  width: 70px;
  object-fit: cover;
}