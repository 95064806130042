.ToolkitPageToolkitItem .title h3 {
  color: black;
  font-size: 25px;
  padding-top: 32px;
}

.ToolkitPageToolkitItem .title h3:after {
  background: rgb(var(--color-grey));
  width: 34px;
  height: 1px;
  content: "";
  display: block;
  margin: 16px 0;
}

.ToolkitPageToolkitItem .category {
  font-size: 11px;
  color: rgb(var(--color-blue-4));
  font-style: italic;
}

.ToolkitPageToolkitItem .RichText {
  color: rgb(var(--color-grey));
  line-height: 20px;
  padding-top: 16px;
  font-family: 'DM Sans', sans-serif;
}