.Quotes {
    color: rgb(var(--color-green));
    font-size: 21px;
    margin-bottom: 25px;
    font-style: italic;

    text-align: center;
}

.Quotes p {
    font-family: 'Libre Baskerville', serif;
    margin-bottom: 0;
}

.Quotes span {
    font-size: 15px;
    font-family: 'Roboto Mono', monospace;
    display: block;
}