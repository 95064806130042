.WelcomeKitContent .nav-tabs {
    margin-bottom: 0 !important;
    border-bottom: unset;
}

.WelcomeKitContent .tab-content {
    background-color: white;
    border: none;
    box-shadow: 0 0 6px #949494;
    padding: 48px;
}

.WelcomeKitContent .nav-item {
    flex: 1;
}

.WelcomeKitContent .nav-item button,
.WelcomeKitContent .nav-item.disabled button {
    width: 100%;
    background: rgb(var(--color-green));
    color: white;
    padding: 8px;
    font-size: 13px;
    height: 100%;
    border-radius: 24px 24px 0px 0px;
    line-height: 16px;
    font-weight: 500;
}

.WelcomeKitContent .nav-item.disabled {
    height: 100%;
}

.WelcomeKitContent .nav-item.disabled button {
    height: 100%;
    opacity: 0.8;
    cursor: default;
}

.WelcomeKitContent .nav-item.active button {
    background-color: white;
    color: black;
}

.WelcomeKitContent .PageModuleSaleContent {
    margin-top: 64px;
}

.TabContent .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
}