.AboutPageContentWhySection h1 {
  color: white !important;
  font-size: 50px;
  font-family: 'Libre Baskerville', serif;
}

.AboutPageContentWhySection li {
  list-style-type: none;
}

.AboutPageContentWhySection .RichText h4:first-of-type {
  font-size: 16px;
  margin: 10px 0;
  line-height: 22px;
  font-family: "DM Sans", sans-serif;
  color: black;
  font-weight: 500;
}

.AboutPageContentWhySection .RichText p {
  line-height: 23px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
}