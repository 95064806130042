.HowItWorks {
    display: flex;
    flex-direction: column;
}

.HowItWorks li:nth-child(2n) {
    padding-left: 30px;
}

.HowItWorks li {
    padding: 0 15px;
    margin-bottom: 70px;
}

.HowItWorks li h3 {
    font-size: 16px;
    font-family: "DM Sans", sans-serif;
    padding: 12px 0px;
}

.HowItWorks p h3 {
    font-family: "DM Sans", sans-serif;
}

.HowItWorks .CustomButton {
    align-self: flex-end;
}