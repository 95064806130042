.AboutTab {
    display: flex;
    flex-direction: column;
}

.AboutTab .title {
    font-size: 28px;
    color: rgb(var(--color-blue-4));
    font-family: 'Libre Baskerville', serif;
}

.AboutTab button {
    align-self: center;
}