.PostcardDetailsSources h3 {
  font-size: 12px;
  color: rgb(var(--color-blue-4));
  line-height: 28px;
  font-family: "DM Sans", sans-serif;
}

.PostcardDetailsSources .RichText {
  line-height: 18px;
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
  color: rgb(var(--color-blue-4));
  line-height: 22px;
  font-family: "DM Sans", sans-serif;
}

.PostcardDetailsSources .RichText a {
  text-decoration: underline;
}